import { setSelectedCard } from "redux/reducer/freedompay/actions";
import { apiCall } from "src/apis/api";
import { BASKET, ORDER_DETAILS } from "api/endpoints";
import {
  CheckInSuccessModalMessage,
  PlaceOrderRequest,
  PlaceOrderStatus,
  PlaceOrderSuccessEventProperties,
  RecentOrderResponse,
} from "src/types/types";
import Analytics from "analytics/Analytics";
import { getPaymentFailureInfo } from "components/Checkout/CheckoutOrderPayment/helper/CheckoutOrderPaymentHelper";
import { setErrorMessageToModal, setErrorMessageToModalFlag } from "../UserStatus/UserStatusAction";
import {
  GET_RECENT_ORDERS_LIST,
  IS_ORDER_CONFIRMED_FLAG,
  IS_ORDER_PLACED_FLAG,
  ORDER_ID,
  GET_ORDER_STATUS,
  RESET_PLACE_ORDER_VALUES,
  SET_TIME_TO_SHOW_RECEIVED,
  TIME_MODE,
  TIME_WANTED,
  SET_ORDER_DETAILS,
  RECENT_ORDER_IS_LOADING,
  GUEST_USER_SIGNUP_MODAL,
  SET_LAST_PAYMENT_METHOD,
  IS_CURBSIDE_ORDER_READY_FOR_DELIVERY,
  SHOW_CURBSIDE_READY_BUTTON,
  VALID_CURBSIDE_ORDERS_LIST,
  SET_CURBSIDE_ORDER_CHECK_IN_SUCCESS_MODAL_DATA,
  SET_CURBSIDE_CHECKIN_MODAL_DATA,
  SET_SHOW_IM_HERE_BUTTON_FLAG,
  RESET_CURBSIDE_ORDER_DETAILS,
  SET_TRIGGER_INIT_API_FLAG,
  SET_CONCURRENT_CURBSIDE_CHECKIN_STATUSES,
  SET_CONCURRENT_BASKET_IDS,
  SET_CONCURRENT_PAYMENT_INFO,
  SET_CONCURRENT_STAR_RATING,
  SET_CONCURRENT_CONTACTLESS_DELIVERY_FLAG,
  SET_CONCURRENT_FAVE_ORDER_FLAG,
  SET_CONCURRENT_ORDER_STATUS_FLAG,
  REMOVE_ORDERS_NOT_IN_CONCURRENT_ORDERS,
} from "./PlaceOrderConst";

export const recentOrderIsLoading = (isLoading: boolean) => {
  return { type: RECENT_ORDER_IS_LOADING, payload: isLoading };
};

export const getRecentOrders = (payload: string | undefined, storeId: number | undefined) => {
  return (dispatch: any) => {
    dispatch(recentOrderIsLoading(true));
    apiCall({
      requestType: "POST",
      apiEndPoint: `${ORDER_DETAILS}recents?basedOnStore=true`,
      apiPostData: {
        accessToken: payload,
        storeId,
      },
    }).then((response) => {
      if (response.success) {
        dispatch({ type: GET_RECENT_ORDERS_LIST, payload: response.response });
      } else {
        dispatch({ type: GET_RECENT_ORDERS_LIST, payload: [] });
      }
    });
  };
};

export const resetRecentOrders = () => {
  return { type: GET_RECENT_ORDERS_LIST, payload: [] };
};

export const orderPlacedFlag = (payload: boolean) => {
  return { type: IS_ORDER_PLACED_FLAG, payload: payload };
};

export const orderConfirmationFlag = (payload: boolean) => {
  return { type: IS_ORDER_CONFIRMED_FLAG, payload: payload };
};

export const getOrderStatus = (payload: PlaceOrderStatus) => {
  return (dispatch: any) => {
    apiCall({
      requestType: "GET",
      apiEndPoint: `${BASKET}/${payload.basketId}/place-order-status`,
    }).then((res: any) => {
      dispatch({ type: GET_ORDER_STATUS, payload: res?.response?.status });
      if (res?.response?.status === "order-placed" && res?.response?.orderId) {
        dispatch({ type: ORDER_ID, payload: res?.response?.orderId });
      } else if (res?.response?.status === "submission-error" || res?.response?.status === "validation-error") {
        dispatch(setErrorMessageToModalFlag(true));
        dispatch(setErrorMessageToModal(res?.response?.message));
        if (res?.response?.code === 6602) {
          Analytics.getInstance().logEvent("kount_rejection_popup", {
            errorMessage: res?.response?.message,
            rejectionType: res?.response?.code,
          });
        }
        payload.lastPaymentMethod.forEach((paymentMethod) => {
          const { analyticConstant, errorCode, errorMessage, paymentType } = getPaymentFailureInfo(
            res?.response?.debugMessage,
            paymentMethod?.paymentType
          );
          let eventProperties: PlaceOrderSuccessEventProperties = { failureMessage: errorMessage, failureCode: errorCode };
          if (paymentType) {
            eventProperties = {
              ...eventProperties,
              ecommerce: {
                paymentType: paymentType ?? paymentType,
              },
            };
          }
          // logging Payment Failure Event
          Analytics.getInstance().logEvent(analyticConstant, {
            ...eventProperties,
          });
        });
      }
    });
  };
};

export const placeOrder = (payload: PlaceOrderRequest, dispatch: any, guestUser?: boolean) => {
  const placeOrderPayloadData: any = {
    timeMode: payload.timeMode,
    timeWanted: payload.timeWanted,
    tipAmount: payload.tipAmount,
    paymentMethods: payload.paymentMethods,
    sessionId: payload.sessionId,
  };

  if (guestUser) {
    placeOrderPayloadData.guestUser = {
      firstName: payload.guestUserDetails?.firstName,
      lastName: payload.guestUserDetails?.lastName,
      email: payload.guestUserDetails?.email,
      phone: payload.guestUserDetails?.phone,
    };
  } else {
    placeOrderPayloadData.accessToken = payload.accessToken;
  }
  dispatch(recentOrderIsLoading(true));
  return apiCall({
    apiEndPoint: `${BASKET}/${payload.basketId}/place-order`,
    requestType: "POST",
    apiPostData: placeOrderPayloadData,
  }).then((res) => {
    if (res.success) {
      dispatch({ type: GET_ORDER_STATUS, payload: res.response?.status });
      if (res.response?.status === "order-placed" && res.response?.orderId) {
        dispatch({ type: ORDER_ID, payload: res.response?.orderId });
        dispatch(setSelectedCard(null));
      }
    } else {
      dispatch(recentOrderIsLoading(false));
      dispatch({ type: GET_ORDER_STATUS, payload: null });
      dispatch(setErrorMessageToModalFlag(true));
      const errorMessageUpdate = res.error;
      dispatch(setErrorMessageToModal(errorMessageUpdate || "Something Went Wrong"));
    }
    return res;
  });
};

export const setReceivedShowTime = (payload: number | null) => {
  return { type: SET_TIME_TO_SHOW_RECEIVED, payload: payload };
};

export const resetPlaceOrderValues = () => {
  return { type: RESET_PLACE_ORDER_VALUES };
};

export const setTimeMode = (payload: string) => {
  return { type: TIME_MODE, payload: payload };
};

export const setTimeWanted = (payload: string) => {
  return { type: TIME_WANTED, payload: payload };
};

export const getPlacedOrderStatus = (payload: string | undefined) => {
  return (dispatch: any) => {
    apiCall({
      apiEndPoint: `${ORDER_DETAILS}${payload}`,
      requestType: "GET",
    })
      .then((response) => {
        dispatch({ type: SET_ORDER_DETAILS, payload: response.response });
      })
      .catch(() => {
        dispatch({ type: GET_ORDER_STATUS, payload: [] });
      });
  };
};

export const setGuestUserSignUpModal = (payload: boolean) => {
  return { type: GUEST_USER_SIGNUP_MODAL, payload: payload };
};

export const setLastPaymentMethod = (payload: any) => {
  return { type: SET_LAST_PAYMENT_METHOD, payload: payload };
};

export const curbsideOrderReadyForDeliveryFlag = (payload: boolean) => {
  return { type: IS_CURBSIDE_ORDER_READY_FOR_DELIVERY, payload: payload };
};

export const showCurbsideReadyButtonFlag = (payload: boolean) => {
  return { type: SHOW_CURBSIDE_READY_BUTTON, payload: payload };
};

export const setValidCurbsideOrders = (payload: Array<RecentOrderResponse>) => {
  return { type: VALID_CURBSIDE_ORDERS_LIST, payload: payload };
};

export const setCurbsideOrderCheckInSuccessModalData = (payload: CheckInSuccessModalMessage) => {
  return { type: SET_CURBSIDE_ORDER_CHECK_IN_SUCCESS_MODAL_DATA, payload: payload };
};

export const setCurbsideCheckInModalData = (payload: Array<string>) => {
  return { type: SET_CURBSIDE_CHECKIN_MODAL_DATA, payload };
};

export const setIsShowImHereButtonFlag = (payload: boolean) => {
  return { type: SET_SHOW_IM_HERE_BUTTON_FLAG, payload };
};

export const resetCurbsideOrderDetails = () => {
  return { type: RESET_CURBSIDE_ORDER_DETAILS };
};

export const setTriggerInitApiFlag = (payload: boolean) => {
  return { type: SET_TRIGGER_INIT_API_FLAG, payload };
};

export const setConcurrentCurbsideCheckinStatus = (payload: { orderId: string; status: string }) => {
  return {
    type: SET_CONCURRENT_CURBSIDE_CHECKIN_STATUSES,
    payload,
  };
};

export const setConcurrentBasketIds = (payload: { orderId: string; basketId: string }) => {
  return {
    type: SET_CONCURRENT_BASKET_IDS,
    payload,
  };
};

export const setConcurrentPaymentInfo = (payload: { orderId: string; paymentInfo: any }) => {
  return {
    type: SET_CONCURRENT_PAYMENT_INFO,
    payload,
  };
};

export const setConcurrentStarRating = (payload: { orderId: string; starRating: number }) => {
  return {
    type: SET_CONCURRENT_STAR_RATING,
    payload,
  };
};

export const setConcurrentContactlessDeliveryFlag = (payload: { basketId: string; contactlessDeliveryFlag: boolean }) => {
  return {
    type: SET_CONCURRENT_CONTACTLESS_DELIVERY_FLAG,
    payload,
  };
};

export const setConcurrentFaveOrderFlag = (payload: { orderId: string; faveOrderFlag: boolean }) => {
  return {
    type: SET_CONCURRENT_FAVE_ORDER_FLAG,
    payload,
  };
};

export const setConcurrentOrderStatusFlag = (payload: { storeId: any; orderStatusFlag: boolean }) => {
  return {
    type: SET_CONCURRENT_ORDER_STATUS_FLAG,
    payload,
  };
};

export const removeOrdersNotInConcurrentOrders = (payload: { concurrentOrders: any }) => {
  return {
    type: REMOVE_ORDERS_NOT_IN_CONCURRENT_ORDERS,
    payload,
  };
};

import thunk from "redux-thunk";
import sessionStorage from "redux-persist/lib/storage/session";
import { combineReducers, legacy_createStore as createStore, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "redux/reducer/user/reducer";
import { signupReducer } from "redux/reducer/auth/signup/reducer";
import { forgotPasswordReducer } from "redux/reducer/auth/forgot-password/reducer";
import freedomPayReducer from "redux/reducer/freedompay/reducer";
import { applePayReducer } from "redux/reducer/applepay/reducer";
import { rewardsReducer } from "redux/reducer/rewards/reducer";
import { storeReducer } from "redux/reducer/Store/reducer";
import { basketReducer } from "redux/reducer/basket/reducer";
import { googlePayReducer } from "redux/reducer/googlepay/reducer";
import Me from "./reducer/Me/MeReducer";
import Address from "./reducer/Address/AddressReducer";
import Menu from "./reducer/Menu/MenuReducer";
import userStatusReducer from "./reducer/UserStatus/UserStatusReducer";
import CMSReducer from "./reducer/CMS/CMSReducer";
import PlaceOrderReducer from "./reducer/PlaceOrder/PlaceOrderReducer";
import LocationReducer from "./reducer/Location/LocationReducer";
import GiftCardReducer from "./reducer/GiftCards/GiftCardsReducer";
import ReOrderReducer from "./reducer/ReOrder/ReOrderReducer";
import DeviceReducer from "./reducer/Device/DeviceReducer";
import { CareersReducer } from "./reducer/Careers/CareersReducer";
import FranchiseReducer from "./reducer/Franchise/FranchiseReducer";
import GlobalReducer from "./reducer/Global/GlobalReducer";
import { CheckoutReducer } from "./reducer/Checkout/CheckoutReducer";
import { WebSocketReducer } from "./reducer/WebSocket/WebSocketReducer";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: ["user", "address", "menu", "basket", "placeOrder", "userStatus", "store", "rewards", "currentDevice", "webSocket"], // We need add whitelist persist
};

export const rootReducer = combineReducers({
  user: userReducer,
  rewards: rewardsReducer,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  store: storeReducer,
  address: Address,
  me: Me,
  menu: Menu,
  basket: basketReducer,
  userStatus: userStatusReducer,
  cms: CMSReducer,
  placeOrder: PlaceOrderReducer,
  payment: freedomPayReducer,
  applepayPayment: applePayReducer,
  location: LocationReducer,
  giftcards: GiftCardReducer,
  reorder: ReOrderReducer,
  currentDevice: DeviceReducer,
  careers: CareersReducer,
  googlePayPayment: googlePayReducer,
  franchise: FranchiseReducer,
  globalReducer: GlobalReducer,
  checkout: CheckoutReducer,
  webSocket: WebSocketReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools({ trace: true, traceLimit: 25 })(applyMiddleware(thunk)));

store.dispatch as any;

export default store;

export const MARK_FAVE_FROM_ORDERS = "MARK_FAVE_FROM_ORDERS";
export const SET_ORDERID_FROM_RECENT_ORDER = "SET_ORDERID_FROM_RECENT_ORDER";
export const CREATE_FAVE_RECENT_ORDERS = "CREATE_FAVE_RECENT_ORDERS";
export const REORDER_CLICKED = "REORDER_CLICKED";
export const SET_REORDER_ITEMID = "SET_REORDER_ITEMID";
export const REORDER_IS_LOADING = "REORDER_IS_LOADING";
export const SET_REORDER_NOTIFICATION_POPUP = "SET_REORDER_NOTIFICATION_POPUP";
export const SET_IS_FAVE_REORDER = "SET_IS_FAVE_REORDER";
export const SET_SHOW_EDIT_FAV_MODAL = "SET_SHOW_EDIT_FAV_MODAL";
export const SET_STORE_ORDER_STATUS = "SET_STORE_ORDER_STATUS";

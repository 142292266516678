import {
  GET_RECENT_ORDERS_LIST,
  SET_ORDER_DETAILS,
  IS_ORDER_PLACED_FLAG,
  IS_ORDER_CONFIRMED_FLAG,
  ORDER_ID,
  SET_TIME_TO_SHOW_RECEIVED,
  RESET_PLACE_ORDER_VALUES,
  TIME_MODE,
  TIME_WANTED,
  GET_ORDER_STATUS,
  RECENT_ORDER_IS_LOADING,
  GUEST_USER_SIGNUP_MODAL,
  SET_LAST_PAYMENT_METHOD,
  IS_CURBSIDE_ORDER_READY_FOR_DELIVERY,
  SHOW_CURBSIDE_READY_BUTTON,
  VALID_CURBSIDE_ORDERS_LIST,
  SET_CURBSIDE_ORDER_CHECK_IN_SUCCESS_MODAL_DATA,
  SET_CURBSIDE_CHECKIN_MODAL_DATA,
  SET_SHOW_IM_HERE_BUTTON_FLAG,
  RESET_CURBSIDE_ORDER_DETAILS,
  SET_TRIGGER_INIT_API_FLAG,
  SET_CONCURRENT_CURBSIDE_CHECKIN_STATUSES,
  SET_CONCURRENT_BASKET_IDS,
  SET_CONCURRENT_PAYMENT_INFO,
  SET_CONCURRENT_STAR_RATING,
  SET_CONCURRENT_CONTACTLESS_DELIVERY_FLAG,
  SET_CONCURRENT_FAVE_ORDER_FLAG,
  REMOVE_ORDERS_NOT_IN_CONCURRENT_ORDERS,
  SET_CONCURRENT_ORDER_STATUS_FLAG,
} from "./PlaceOrderConst";

const initialState = {
  isLoading: false,
  orderDetails: null,
  recentOrdersList: [],
  isOrderPlaced: false,
  isOrderConfirmed: false,
  orderStatus: null,
  orderId: null,
  timeToShowReceived: null,
  timeMode: "",
  timeWanted: "",
  deliveryStatus: [{ status: "Pending" }],
  guestUserSignUpModal: false,
  lastPaymentMethod: [],
  isCurbSideOrderReadyForDelivery: false,
  showCurbsideReadyButton: false,
  validCurbsideOrdersList: [],
  successModalData: {},
  curbsideCheckInModalData: [],
  isShowImHereButtonFlag: false,
  triggerInitApiCallFlag: false,
  concurrentDetails: {
    orderStatusFlag: {},
    basketIds: {},
    paymentInfo: {},
    starRating: {},
    faveOrderFlag: {},
    contactlessDeliveryFlag: {},
    curbsideCheckinStatuses: {},
  },
};

export default function PlaceOrderReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case RECENT_ORDER_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload || false,
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case GET_RECENT_ORDERS_LIST:
      return {
        ...state,
        recentOrdersList: action.payload,
        isLoading: false,
      };
    case IS_ORDER_PLACED_FLAG:
      return {
        ...state,
        isOrderPlaced: action.payload,
      };

    case IS_ORDER_CONFIRMED_FLAG:
      return {
        ...state,
        isOrderConfirmed: action.payload,
      };

    case ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };

    case SET_TIME_TO_SHOW_RECEIVED:
      return {
        ...state,
        timeToShowReceived: action.payload,
      };

    case RESET_PLACE_ORDER_VALUES:
      return {
        ...state,
        orderDetails: null,
        isOrderPlaced: false,
        isOrderConfirmed: false,
        orderStatus: null,
        orderId: null,
        isLoading: false,
      };

    case TIME_MODE:
      return {
        ...state,
        timeMode: action.payload,
      };

    case TIME_WANTED:
      return {
        ...state,
        timeWanted: action.payload,
      };

    case GET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.payload,
      };

    case GUEST_USER_SIGNUP_MODAL:
      return {
        ...state,
        guestUserSignUpModal: action.payload,
      };

    case SET_LAST_PAYMENT_METHOD:
      return {
        ...state,
        lastPaymentMethod: action.payload,
      };
    case IS_CURBSIDE_ORDER_READY_FOR_DELIVERY:
      return {
        ...state,
        isCurbSideOrderReadyForDelivery: action.payload,
      };
    case SHOW_CURBSIDE_READY_BUTTON:
      return {
        ...state,
        showCurbsideReadyButton: action.payload,
      };
    case VALID_CURBSIDE_ORDERS_LIST:
      return {
        ...state,
        validCurbsideOrdersList: action.payload,
      };
    case SET_CURBSIDE_ORDER_CHECK_IN_SUCCESS_MODAL_DATA:
      return {
        ...state,
        successModalData: action.payload,
      };
    case SET_CURBSIDE_CHECKIN_MODAL_DATA:
      return {
        ...state,
        curbsideCheckInModalData: action.payload,
      };
    case SET_SHOW_IM_HERE_BUTTON_FLAG:
      return {
        ...state,
        isShowImHereButtonFlag: action.payload,
      };
    case RESET_CURBSIDE_ORDER_DETAILS:
      return {
        ...state,
        isCurbSideOrderReadyForDelivery: false,
        showCurbsideReadyButton: false,
        validCurbsideOrdersList: [],
        successModalData: {},
        curbsideCheckInModalData: [],
        isShowImHereButtonFlag: false,
      };
    case SET_TRIGGER_INIT_API_FLAG:
      return {
        ...state,
        triggerInitApiCallFlag: action.payload,
      };
    case SET_CONCURRENT_CURBSIDE_CHECKIN_STATUSES:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          curbsideCheckinStatuses: {
            ...state?.concurrentDetails?.curbsideCheckinStatuses,
            [action.payload.orderId]: action.payload.status,
          },
        },
      };
    case SET_CONCURRENT_BASKET_IDS:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          basketIds: {
            ...state?.concurrentDetails?.basketIds,
            [action.payload.orderId]: action.payload.basketId,
          },
        },
      };
    case SET_CONCURRENT_PAYMENT_INFO:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          paymentInfo: {
            ...state?.concurrentDetails?.paymentInfo,
            [action?.payload?.orderId]: action?.payload?.paymentInfo,
          },
        },
      };
    case SET_CONCURRENT_STAR_RATING:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          starRating: {
            ...state?.concurrentDetails?.starRating,
            [action?.payload?.orderId]: action?.payload?.starRating,
          },
        },
      };
    case SET_CONCURRENT_CONTACTLESS_DELIVERY_FLAG:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          contactlessDeliveryFlag: {
            ...state?.concurrentDetails?.contactlessDeliveryFlag,
            [action?.payload?.basketId]: action?.payload?.contactlessDeliveryFlag,
          },
        },
      };
    case SET_CONCURRENT_FAVE_ORDER_FLAG:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          faveOrderFlag: {
            ...state?.concurrentDetails?.faveOrderFlag,
            [action?.payload?.orderId]: action?.payload?.faveOrderFlag,
          },
        },
      };
    case SET_CONCURRENT_ORDER_STATUS_FLAG:
      return {
        ...state,
        concurrentDetails: {
          ...state?.concurrentDetails,
          orderStatusFlag: {
            ...state?.concurrentDetails?.orderStatusFlag,
            [action?.payload?.storeId]: action?.payload?.orderStatusFlag,
          },
        },
      };
    case REMOVE_ORDERS_NOT_IN_CONCURRENT_ORDERS: {
      const concurrentOrders = action?.payload?.concurrentOrders || [];
      const concurrentOrderIds = concurrentOrders.map((order: any) => order.orderId);

      return {
        ...state,
        concurrentDetails: {
          orderStatusFlag: {},
          starRating: Object.fromEntries(
            Object.entries(state?.concurrentDetails?.starRating || {}).filter(([key]) => concurrentOrderIds.includes(key))
          ),
          basketIds: Object.fromEntries(
            Object.entries(state?.concurrentDetails?.basketIds || {}).filter(([key]) => concurrentOrderIds.includes(key))
          ),
          paymentInfo: Object.fromEntries(
            Object.entries(state?.concurrentDetails?.paymentInfo || {}).filter(([key]) => concurrentOrderIds.includes(key))
          ),
          curbsideCheckinStatuses: Object.fromEntries(
            Object.entries(state?.concurrentDetails?.curbsideCheckinStatuses || {}).filter(([key]) => concurrentOrderIds.includes(key))
          ),
          faveOrderFlag: Object.fromEntries(
            Object.entries(state?.concurrentDetails?.faveOrderFlag || {}).filter(([key]) => concurrentOrderIds.includes(key))
          ),
          contactlessDeliveryFlag: Object.fromEntries(
            Object.entries(state?.concurrentDetails?.contactlessDeliveryFlag || {}).filter(([key]) =>
              Object.values(state?.concurrentDetails?.basketIds || {}).includes(key)
            )
          ),
        },
      };
    }
    default:
      return state;
  }
}

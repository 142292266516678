import { PropsWithChildren, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import mParticle from "@mparticle/web-sdk";
import { useMParticle } from "hooks/useMParticle";
import { getStoreDetails } from "redux/reducer/Store/actions";
import Footer from "components/global/Footer/Footer";
import ErrorModal from "components/atomic-components/molecules/ErrorModal/ErrorModal";
import NotAvailableForDelivery from "components/Menu/NotAvailableForDelivery/NotAvailableForDelivery";
import StoreRequestConfirmation from "components/stores/StoreRequestConfirmation/StoreRequestConfirmation";
import AccountCreated from "containers/AccountCreated/AccountCreated";
import FaveCreatedConfirmationModal from "containers/Basket/modals/FaveCreatedConfirmationModal/FaveCreatedConfirmationModal";
import ForgotPassword from "containers/ForgotPassword/ForgotPassword";
import Login from "containers/Login/Login";
import OrderNow from "containers/Order/Order";
import Signup from "containers/Signup/Signup";
import Verify from "containers/Verify/Verify";
import ViolatorBar from "components/atomic-components/molecules/ViolatorBar/ViolatorBar";
import useModal from "hooks/useModal";
import CurbsideBanner from "containers/CurbsideBanner/CurbsideBanner";
import ProfileVerifyEnterCode from "containers/Account/Profile/Profile/modals/ProfileVerifyEnterCode/ProfileVerifyEnterCode";
import ProfilePhoneUpdated from "containers/Account/Profile/Profile/modals/ProfilePhoneUpdated/ProfilePhoneUpdated";
import CheckinModal from "containers/CurbsideBanner/CurbsideCheckinModal/CheckinModal";
import CheckinModalRebrand from "containers/CurbsideBanner/CurbsideCheckinModalRebrand/CheckinModalRebrand";
import CheckinSuccessModal from "containers/CurbsideBanner/CurbsideCheckinModal/CheckinSuccessModal";
import ViewOrdersModal from "containers/CurbsideBanner/components/ViewOrders/ViewOrdersModal";
import { RootState } from "src/types/types";
import { getSessionStorageData, setSessionStorageData } from "utils/storage";
import { setSigninSignupMessage } from "redux/reducer/CMS/CMSAction";
import { getRecentOrders } from "redux/reducer/PlaceOrder/PlaceOrderActions";
import { setErrorMessageToModalFlag } from "redux/reducer/UserStatus/UserStatusAction";
import ItemsNotAddedModal from "components/atomic-components/molecules/Modals/ItemsNotAddedModal/ItemsNotAddedModal";
import CancelAccountCreationPopup from "components/atomic-components/molecules/CancelAccountCreationPopup/CancelAccountCreationPopup";
import SelectStoreModal from "containers/Order/modals/SelectStoreModal/SelectStoreModal";
import { useKountInit } from "containers/AppWrapper/useKountInit";
import FranchiseFooter from "components/global/Footer/Franchise/FranchiseFooter";
import GetStartedConfirmationModal from "components/Franchise/GetStarted/GetStartedConfirmationModal";
import ProductNotAvailableModal from "../ProductNotAvailableModal/ProductNotAvailableModal";
import { useRedirectURL } from "./useRedirectURL";

const Header = dynamic(() => import("components/global/Header/Header"), {
  ssr: false,
});

const FranchiseHeader = dynamic(() => import("components/global/Header/Franchise/FranchiseHeader"), {
  ssr: false,
});

interface Props extends PropsWithChildren {
  isFranchise?: boolean;
}

const PageWrapper = ({ children, isFranchise = false }: Props) => {
  const dispatch = useDispatch();
  const { tokens, selectedStoreId, errorMessageToModal, isSigninLoginMessageLoaded, itemsNotTransferred } = useSelector(
    (state: RootState) => ({
      tokens: state.user.tokens,
      selectedStoreId: state.store?.selectedStoreId,
      errorMessageToModal: state.userStatus?.errorMessageToModal,
      isSigninLoginMessageLoaded: state.cms?.isSigninLoginMessageLoaded,
      itemsNotTransferred: state.basket?.itemsNotTransferred,
    })
  );

  const [currentUserLocation, setCurrentUserLocation] = useState({ latitude: 0, longitude: 0 });
  const { modal } = useModal();
  const { mParticleLoginIdentity } = useMParticle();
  const mParticleUserIdentity = mParticle?.Identity?.getCurrentUser()?.getUserIdentities()?.userIdentities?.email;
  // Updating the modal & re-directing based on the URL is handled in the useRedirectURL hook
  useRedirectURL();

  // Kount Initialization
  useKountInit();

  // Removed code from the HomePage to show the curbside banner all over the app
  useEffect(() => {
    if (tokens?.accessToken) {
      dispatch(getRecentOrders(tokens?.accessToken, selectedStoreId));
    }
  }, [tokens?.accessToken, selectedStoreId]);

  // Removed code from the HomePage to show the curbside banner all over the app
  useEffect(() => {
    if (selectedStoreId) {
      // @ts-ignore // revisit while fixing all store types
      dispatch(getStoreDetails(selectedStoreId));
    }
  }, [selectedStoreId]);

  // Set the current user location if the user is granted access for it
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  useEffect(() => {
    // If user selected the location then select the store details
    if (mParticle && currentUserLocation.latitude && currentUserLocation.longitude) {
      // Log the event with the current user location
      mParticle?.setPosition(currentUserLocation.latitude, currentUserLocation.longitude);
    }
  }, [currentUserLocation.latitude, currentUserLocation.longitude]);

  useEffect(() => {
    !isSigninLoginMessageLoaded && dispatch(setSigninSignupMessage());
  }, [isSigninLoginMessageLoaded]);

  const showRewardNotApplicableModal = getSessionStorageData("REWARD_NOT_APPLICABLE") === "true"; // returns either true or false

  useEffect(() => {
    if (showRewardNotApplicableModal) {
      dispatch(setErrorMessageToModalFlag(true));
    }
  }, [showRewardNotApplicableModal]);

  useEffect(() => {
    const isMparticleLoginEnabled = getSessionStorageData("isMparticleLoginEnabled") === "true";
    if (mParticle && !isMparticleLoginEnabled && mParticleUserIdentity) {
      const userIdentities = {
        email: mParticleUserIdentity,
      };
      mParticleLoginIdentity(userIdentities);
    }
  }, [mParticle, mParticleUserIdentity]);

  const onCloseRewardNotApplicableModal = () => {
    dispatch(setErrorMessageToModalFlag(false));
    setSessionStorageData("REWARD_NOT_APPLICABLE", "false");
  };

  const MODAL = {
    "": null,
    LOGIN: <Login />,
    SIGNUP: <Signup />,
    VERIFY: <Verify />,
    ACCOUNT_CREATED: <AccountCreated />,
    FORGOT_PASSWORD: <ForgotPassword />,
    ENTER_CODE: <ProfileVerifyEnterCode />,
    ORDER_NOW: <OrderNow />,
    UPDATED: <ProfilePhoneUpdated />,
    SUBMIT_REQUEST_CONFIRMATION: <StoreRequestConfirmation />,
    SUBMIT_CATERING_REQUEST: <StoreRequestConfirmation />,
    FAVE_CREATED_CONFIRMATION: (
      <FaveCreatedConfirmationModal
        onBack={() => {
          // do nothing
        }}
      />
    ),
    DELIVERY_NOT_AVAILABLE: <NotAvailableForDelivery />,
    CURBSIDE_CHECKIN_MODAL: <CheckinModal />,
    CURBSIDE_CHECKIN_MODAL_REBRAND: <CheckinModalRebrand />,
    CURBSIDE_CHECKIN_SUCCESS_MODAL: <CheckinSuccessModal />,
    CURBSIDE_VIEW_ORDERS_MODAL: <ViewOrdersModal />,
    SELECT_STORE_MODAL: <SelectStoreModal />,
    FRANCHISE_GET_STARTED_CONFIRMATION: <GetStartedConfirmationModal />,
    PRODUCT_NOT_AVAILABLE: <ProductNotAvailableModal />,
    CANCEL_ACCOUNT_CREATION: <CancelAccountCreationPopup />,
  }[modal];

  return (
    <>
      {!isFranchise ? (
        <>
          <Header />
          <ViolatorBar className="violatorBar" />
        </>
      ) : (
        <FranchiseHeader />
      )}
      {MODAL}
      {children}
      <CurbsideBanner />
      {showRewardNotApplicableModal && (
        <ErrorModal
          heading={errorMessageToModal?.title ?? ""}
          subHeading={errorMessageToModal?.message}
          onClose={onCloseRewardNotApplicableModal}
        />
      )}
      {!isFranchise ? <Footer /> : <FranchiseFooter />}
      {itemsNotTransferred?.length > 0 && <ItemsNotAddedModal />}
    </>
  );
};

export default PageWrapper;

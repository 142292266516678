import { useRouter } from "next/router";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/types/types";
import { PARKING_SPACE_ERROR } from "constants/constant";
import {
  getRecentOrders,
  setCurbsideCheckInModalData,
  setCurbsideOrderCheckInSuccessModalData,
  setIsShowImHereButtonFlag,
} from "redux/reducer/PlaceOrder/PlaceOrderActions";
import CloseButton from "components/atomic-components/atoms/button/CloseButton/CloseButton";
import Button from "components/atomic-components/atoms/button/Button";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { HorizontalLine } from "components/atomic-components/atoms/HorizontalLineStyled/HorizontalLineStyled";
import Modal from "components/global/Modal/Modal";
import useModal from "hooks/useModal";
import { checkInCurbsideOrder } from "api/orders/checkin-curbside-order";
import Analytics from "analytics/Analytics";
import { ANALYTICS_CONST } from "constants/analytics";
import { CheckinModalStyled } from "./styles/CheckinModalStyled";

const CheckinModal = () => {
  const dispatch = useDispatch();
  const { tokens, curbsideCheckInModalData, selectedStoreId } = useSelector((state: RootState) => ({
    tokens: state.user.tokens,
    curbsideCheckInModalData: state?.placeOrder?.curbsideCheckInModalData,
    selectedStoreId: state.store?.selectedStoreId,
  }));
  const [parkingInfo, setParkingInfo] = useState("");
  const [error, setError] = useState("");

  const { modal, setModal } = useModal();
  const router = useRouter();

  const onChange = (name: string, value: string, e?: ChangeEvent<HTMLInputElement>) => {
    setParkingInfo(value);
    setError("");
  };

  const onSubmitHandler = () => {
    if (error) setError("");
    if (!parkingInfo || parkingInfo.trim().length === 0) setError(PARKING_SPACE_ERROR);
    if (curbsideCheckInModalData.length > 0 && parkingInfo.trim().length > 0) {
      const postOrderData = curbsideCheckInModalData?.map((orderId) => ({
        orderId,
        ParkingSpot: parkingInfo,
        LicensePlate: "",
        PreferredLocation: "",
        Comments: "",
      }));

      Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.CLICK_SUBMIT_CHECK_IN, { platform: "web", page_name: router.pathname }, false);

      checkInCurbsideOrder(tokens?.accessToken, postOrderData).then((response: any) => {
        if (response.success) {
          const { responseMessage } = response.response;
          setParkingInfo("");
          setModal("CURBSIDE_CHECKIN_SUCCESS_MODAL");
          dispatch(setCurbsideCheckInModalData([]));
          dispatch(setCurbsideOrderCheckInSuccessModalData(responseMessage));
          dispatch(getRecentOrders(tokens?.accessToken, selectedStoreId));

          if (router.pathname === "/confirmation") {
            dispatch(setIsShowImHereButtonFlag(false));
          }
        }
      });
    }
  };

  return (
    <CheckinModalStyled>
      <Modal isOpen={modal === "CURBSIDE_CHECKIN_MODAL"} className="checkinModal" overlayClassName="CheckinOverlay">
        <div className="headerButtons">
          <CloseButton variant="eleventh" className="closeBtn" showText={false} onClick={() => setModal("")} />
        </div>
        <div className="modalContent">
          <Headings variant="primaryHeading2" mobileFontSize={28} className="headings">
            You&apos;re Here!
          </Headings>
          <HorizontalLine className="hr" />
          <Paragraph variant="primaryParagraph1" mobileFontWeight={500} mobileFontSize={16} className="subContent">
            Tell us what parking spot you’re in
          </Paragraph>

          <TextField
            label="Parking Space"
            onChange={onChange}
            value={parkingInfo}
            name="parking-space"
            maxLength={114}
            required={true}
            error={error}
          />

          <Button variant="primaryBlue" onClick={() => onSubmitHandler()} className="checkInButton" disabled={!parkingInfo}>
            CHECK IN
          </Button>
        </div>
      </Modal>
    </CheckinModalStyled>
  );
};

export default CheckinModal;

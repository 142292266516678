import React from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import useModal from "hooks/useModal";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { setCurbsideCheckInModalData } from "redux/reducer/PlaceOrder/PlaceOrderActions";
import { ANALYTICS_CONST } from "constants/analytics";
import Analytics from "analytics/Analytics";
import OrderedItemDetailsAccordion from "./OrderedItemDetailsAccordion";

interface Props {
  curbsideOrderDetails: string;
  sortedCurbsideOrdersList: any;
}

const OrderItemAccordion = ({ curbsideOrderDetails, sortedCurbsideOrdersList }: Props) => {
  const dispatch = useDispatch();
  const curbsideOrderIdList = sortedCurbsideOrdersList[curbsideOrderDetails]?.map(
    (curbsideOrderDetail: any) => curbsideOrderDetail.orderId
  );

  const { setModal } = useModal();
  const router = useRouter();

  const storeDetails = sortedCurbsideOrdersList[curbsideOrderDetails][0]?.storeDetails;

  const handleImHereButton = () => {
    Analytics.getInstance().logEvent(ANALYTICS_CONST.ORDER.CLICK_IM_HERE, { platform: "web", page_name: router.pathname }, false);
    setModal("CURBSIDE_CHECKIN_MODAL");
    dispatch(setCurbsideCheckInModalData(curbsideOrderIdList));
  };

  return (
    <>
      <div className="storeDetails">
        <div className="storeName">
          <Paragraph variant="secondaryParagraph4" className="address" mobileFontWeight={700} mobileFontSize={16}>
            {storeDetails?.address}
          </Paragraph>
          <Paragraph variant="primaryParagraph2">
            {storeDetails?.city} , {storeDetails?.state}, {storeDetails?.zip}
          </Paragraph>
        </div>
        <Button className="imhere" variant="primaryRed" onClick={handleImHereButton}>
          I&apos;M HERE
        </Button>
      </div>
      {sortedCurbsideOrdersList[curbsideOrderDetails]?.map((curbsideOrderDetail: any) => (
        <OrderedItemDetailsAccordion key={curbsideOrderDetail.orderId} orderDetails={curbsideOrderDetail} />
      ))}
    </>
  );
};

export default OrderItemAccordion;

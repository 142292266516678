/* eslint-disable no-unused-vars */
export interface WebSocketMessage {
  type: string;
  payload: any;
}

export interface WebSocketState {
  connected?: boolean;
  messages?: any;
  socket?: WebSocket | null;
  url?: string;
  orderId?: string;
  userId?: string;
  storeId?: string;
  error?: any;
}

export enum WebSocketActionTypes {
  OPEN_WEBSOCKET = "OPEN_WEBSOCKET",
  CLOSE_WEBSOCKETS = "CLOSE_WEBSOCKETS",
  SEND_WEBSOCKET_MESSAGE = "SEND_WEBSOCKET_MESSAGE",
  RECEIVE_WEBSOCKET_MESSAGE = "RECEIVE_WEBSOCKET_MESSAGE",
  WEBSOCKET_ERROR = "WEBSOCKET_ERROR",
  UPDATE_WEBSOCKET_MESSAGES = "UPDATE_WEBSOCKET_MESSAGES",
}

export interface OpenWebSocketAction {
  type: WebSocketActionTypes.OPEN_WEBSOCKET;
  payload: any;
}

export interface CloseWebSocketsAction {
  type: WebSocketActionTypes.CLOSE_WEBSOCKETS;
}

export interface SendWebSocketMessageAction {
  type: WebSocketActionTypes.SEND_WEBSOCKET_MESSAGE;
  payload: WebSocketMessage;
}

export interface ReceiveWebSocketMessageAction {
  type: WebSocketActionTypes.RECEIVE_WEBSOCKET_MESSAGE;
  payload: any;
}

export interface WebSocketErrorAction {
  type: WebSocketActionTypes.WEBSOCKET_ERROR;
  payload: any;
}
export interface UpdateWebSocketMessagesAction {
  type: WebSocketActionTypes.UPDATE_WEBSOCKET_MESSAGES;
  payload: any;
}

export type WebSocketActions =
  | OpenWebSocketAction
  | CloseWebSocketsAction
  | SendWebSocketMessageAction
  | ReceiveWebSocketMessageAction
  | UpdateWebSocketMessagesAction
  | WebSocketErrorAction;

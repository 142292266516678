import router from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setFaveConfirmationFlag, setFaveDisableFlag, setFaveModal, setSelectedFavId } from "redux/reducer/basket/actions";
import AuthCard from "components/atomic-components/atoms/layout-elements/AuthCard/AuthCard";
import Button from "components/atomic-components/atoms/button/Button";
import Modal from "components/atomic-components/atoms/layout-elements/Modal/Modal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import StackedButtonsContainer from "components/global/StackedButtonsContainer/StackedButtonsContainer";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { RootState } from "src/types/types";
import { FaveCreatedConfirmationModalStyled } from "./FaveCreatedConfirmationModalStyled";

interface Props {
  onBack: () => void;
  hideCheckoutCartBtn?: boolean;
}

const FaveCreatedConfirmationModal = ({ onBack, hideCheckoutCartBtn = false }: Props) => {
  const dispatch = useDispatch();

  const { faveRecentConfirmationFlag, selectedStoreId, device } = useSelector((state: RootState) => ({
    userFavoriteBasketName: state.basket?.userFavoriteBasketName,
    faveRecentConfirmationFlag: state.basket?.faveRecentConfirmationFlag,
    selectedStoreId: state.store?.selectedStoreId,
    device: state?.currentDevice.device,
  }));

  useEffect(() => {
    dispatch(setFaveDisableFlag(false));
  }, []);

  const onClose = () => {
    dispatch(setFaveConfirmationFlag(false));
    dispatch(setFaveModal(""));
    router.push("/cart");
  };

  const handleCheckOut = () => {
    dispatch(setFaveConfirmationFlag(false));
    dispatch(setFaveModal(""));
    router.push("/checkout");
    if (faveRecentConfirmationFlag) {
      onBack();
      dispatch(setFaveConfirmationFlag(false));
    }
  };

  const handleView = () => {
    dispatch(setFaveModal(""));
    dispatch(setSelectedFavId(undefined));
    if (faveRecentConfirmationFlag) {
      onBack();
      dispatch(setFaveConfirmationFlag(false));
    }
    router.push(
      {
        pathname: "/menu",
        query: { initialTab: "Faves", storeId: selectedStoreId },
      },
      "/menu"
    );
  };

  const Body = (
    <div className="bodyContent">
      <Headings variant="primaryHeading2" fontSize={40} fontWeight={700} mobileFontSize={24} mobileFontWeight={800} className="title">
        YOUR FAVE IS SAVED!
      </Headings>
      <Paragraph variant="secondaryParagraph3" mobileFontSize={18} className="subTextBlock blockText">
        Now you can reorder your Fave faster than you can say ‘Crinkle Fries’
      </Paragraph>
    </div>
  );
  const Footer = (
    <div className="content">
      <StackedButtonsContainer direction="row" mobileDirection="col" className="createStackedButtons">
        <Button variant="secondaryWhite" onClick={handleView} data-testid="viewFavBtn">
          {device === "DESKTOP" ? "view fave orders" : "view favorite orders"}
        </Button>
        {router.pathname === "/confirmation" && (
          <Button variant="primaryRed" onClick={onBack} data-testid="viewFavBackToOrderBtn">
            BACK TO YOUR ORDER
          </Button>
        )}
        {hideCheckoutCartBtn === false && (
          <Button variant="primaryRed" onClick={handleCheckOut}>
            CHECKOUT
          </Button>
        )}
      </StackedButtonsContainer>
    </div>
  );
  return (
    <FaveCreatedConfirmationModalStyled>
      <Modal isOpen={true} className="modalContent">
        <AuthCard
          isOpen={true}
          showCloseButton={true}
          onClose={hideCheckoutCartBtn ? onBack : onClose}
          body={Body}
          footer={Footer}
          className="authCard faveConfirmAuthCard"
        />
      </Modal>
    </FaveCreatedConfirmationModalStyled>
  );
};
export default FaveCreatedConfirmationModal;

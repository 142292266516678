import { WebSocketActionTypes, WebSocketState, WebSocketActions } from "./WebSocketTypes";

const initialState: { [key: string]: WebSocketState } = {};
const orderSteps = ["CONFIRMED", "PREPARING", "BAGGING", "READY"];

export const WebSocketReducer = (state = initialState, action: WebSocketActions): WebSocketState => {
  switch (action.type) {
    case WebSocketActionTypes.OPEN_WEBSOCKET:
      return {
        ...state,
        [action.payload.orderId]: {
          ...state[action.payload.orderId],
          connected: true,
          socket: action.payload.socket,
          url: action.payload.url,
          orderId: action.payload.orderId,
          userId: action.payload.userId,
          storeId: action.payload.storeId,
          error: null,
          messages: action.payload.messages,
        },
      };

    case WebSocketActionTypes.CLOSE_WEBSOCKETS: {
      return {};
    }

    case WebSocketActionTypes.RECEIVE_WEBSOCKET_MESSAGE: {
      const { orderId, orderStatus } = action.payload.data;

      if (!state[orderId]) return state;

      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          messages: {
            ...state[orderId]?.messages,
            status: orderStatus,
          },
        },
      };
    }

    case WebSocketActionTypes.UPDATE_WEBSOCKET_MESSAGES: {
      const { messages, orderConfirmationList } = action.payload;
      const orderConfirmationSet = new Set(orderConfirmationList.map((order: any) => order?.orderRef));

      const updatedState = { ...state };

      messages?.forEach((order: any) => {
        const { orderId, status } = order;
        const currentOrderStatus = updatedState[orderId]?.messages?.orderStatus;

        if (
          !currentOrderStatus ||
          (currentOrderStatus && orderSteps.indexOf(status?.toLowerCase()) > orderSteps.indexOf(currentOrderStatus?.toLowerCase()))
        ) {
          updatedState[orderId] = {
            ...updatedState[orderId],
            messages: {
              ...updatedState[orderId]?.messages,
              orderStatus: status,
            },
          };
        }
      });

      Object.keys(updatedState).forEach((orderId) => {
        if (!orderConfirmationSet.has(orderId)) {
          delete updatedState[orderId];
        }
      });

      return updatedState;
    }

    case WebSocketActionTypes.SEND_WEBSOCKET_MESSAGE:
      return {
        ...state,
      };

    case WebSocketActionTypes.WEBSOCKET_ERROR: {
      const { orderId, error } = action.payload;

      return {
        ...state,
        [orderId]: {
          ...state[orderId],
          error,
        },
      };
    }

    default:
      return state;
  }
};

export const SET_ORDER_DETAILS = "SET_ORDER_DETAILS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_RECENT_ORDERS_LIST = "GET_RECENT_ORDERS_LIST";
export const IS_ORDER_PLACED_FLAG = "IS_ORDER_PLACED_FLAG";
export const IS_ORDER_CONFIRMED_FLAG = "IS_ORDER_CONFIRMED_FLAG";
export const ORDER_ID = "ORDER_ID";
export const SET_TIME_TO_SHOW_RECEIVED = "SET_TIME_TO_SHOW_RECEIVED";
export const RESET_PLACE_ORDER_VALUES = "RESET_PLACE_ORDER_VALUES";
export const TIME_MODE = "TIME_MODE";
export const TIME_WANTED = "TIME_WANTED";
export const GET_ORDER_STATUS = "GET_ORDER_STATUS";
export const RECENT_ORDER_IS_LOADING = "RECENT_ORDER_IS_LOADING";
export const GUEST_USER_SIGNUP_MODAL = "GUEST_USER_SIGNUP_MODAL";
export const SET_LAST_PAYMENT_METHOD = "SET_LAST_PAYMENT_METHOD";
export const IS_CURBSIDE_ORDER_READY_FOR_DELIVERY = "IS_CURBSIDE_ORDER_READY_FOR_DELIVERY";
export const SHOW_CURBSIDE_READY_BUTTON = "SHOW_CURBSIDE_READY_BUTTON";
export const VALID_CURBSIDE_ORDERS_LIST = "VALID_CURBSIDE_ORDERS_LIST";
export const SET_CURBSIDE_ORDER_CHECK_IN_SUCCESS_MODAL_DATA = "SET_CURBSIDE_ORDER_CHECK_IN_SUCCESS_MODAL_DATA";
export const SET_CURBSIDE_CHECKIN_MODAL_DATA = "SET_CURBSIDE_CHECKIN_MODAL_DATA";
export const SET_SHOW_IM_HERE_BUTTON_FLAG = "SET_SHOW_IM_HERE_BUTTON_FLAG";
export const RESET_CURBSIDE_ORDER_DETAILS = "RESET_CURBSIDE_ORDER_DETAILS";
export const SET_TRIGGER_INIT_API_FLAG = "SET_TRIGGER_INIT_API_FLAG";
export const SET_CONCURRENT_CURBSIDE_CHECKIN_STATUSES = "SET_CONCURRENT_CURBSIDE_CHECKIN_STATUSES";
export const SET_CONCURRENT_BASKET_IDS = "SET_CONCURRENT_BASKET_IDS";
export const SET_CONCURRENT_PAYMENT_INFO = "SET_CONCURRENT_PAYMENT_INFO";
export const SET_CONCURRENT_STAR_RATING = "SET_CONCURRENT_STAR_RATING";
export const SET_CONCURRENT_CONTACTLESS_DELIVERY_FLAG = "SET_CONCURRENT_CONTACTLESS_DELIVERY_FLAG";
export const SET_CONCURRENT_FAVE_ORDER_FLAG = "SET_CONCURRENT_FAVE_ORDER_FLAG";
export const SET_CONCURRENT_ORDER_STATUS_FLAG = "SET_CONCURRENT_ORDER_STATUS_FLAG";
export const REMOVE_ORDERS_NOT_IN_CONCURRENT_ORDERS = "REMOVE_ORDERS_NOT_IN_CONCURRENT_ORDERS";

import styled from "styled-components";

export const CheckinModalRebrandStyled = styled.div`
  .checkinModal {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding-block: 24px;
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .headerButtons {
      margin-bottom: 48px;
      padding-right: 24px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .closeBtn {
        padding: 0;
      }
      ${(props) => props.theme.responsive?.max.lg} {
        position: relative;
        right: 24px;
        top: 48px;
        margin-bottom: 0;
        padding-right: 0;
      }
    }
    .hr {
      width: 100%;
      margin: 25px 0;
    }
    .checkInButton {
      width: 200px;
      height: 36px;
    }
    .modalContent {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      .subContent {
        margin-bottom: 25px;
        color: ${(props) => props.theme.color.core.blue};
      }
      ${(props) => props.theme.responsive?.max.lg} {
        margin-top: 40px;
      }
    }
  }

  .textFieldWrapper {
    margin-bottom: 45px;
    .input-wrapper {
      width: 294px;
      input {
        font-size: 16px;
      }
      label {
        font-size: 16px;
      }
    }
  }

  .headings {
    max-width: 384px;
  }
`;

import { createContext, useState, ReactNode } from "react";

export type ModalValues =
  | ""
  | "LOGIN"
  | "SIGNUP"
  | "ENTER_CODE"
  | "VERIFY"
  | "ACCOUNT_CREATED"
  | "FORGOT_PASSWORD"
  | "ORDER_NOW"
  | "SUBMIT_REQUEST_CONFIRMATION"
  | "FAVE_CREATED_CONFIRMATION"
  | "UPDATED"
  | "DELIVERY_NOT_AVAILABLE"
  | "CURBSIDE_CHECKIN_MODAL"
  | "CURBSIDE_CHECKIN_MODAL_REBRAND"
  | "CURBSIDE_CHECKIN_SUCCESS_MODAL"
  | "CURBSIDE_VIEW_ORDERS_MODAL"
  | "SELECT_STORE_MODAL"
  | "FRANCHISE_GET_STARTED_CONFIRMATION"
  | "PRODUCT_NOT_AVAILABLE"
  | "CANCEL_ACCOUNT_CREATION";

interface Context {
  modal: ModalValues;
  setModal: (value: ModalValues) => void;
}

interface Props {
  children: ReactNode;
}

export const ModalContext = createContext({} as Context);

export const ModalProvider = ({ children }: Props) => {
  const [modal, setModal] = useState<ModalValues>("");

  return <ModalContext.Provider value={{ modal, setModal }}>{children}</ModalContext.Provider>;
};

const HOST = process.env.NEXT_PUBLIC_API_END_POINT;
const VERSION = process.env.NEXT_PUBLIC_END_POINT_VERSION ?? "v1";

export const SIGNUP = `${HOST}/${VERSION}/users/signup`; // used to create new user
export const SIGNIN = `${HOST}/${VERSION}/users/signin`; // used to authenticate existing user
export const SIGNOUT = `${HOST}/${VERSION}/users/signout`; // used to log out user globally
export const REVOKE_TOKEN = `${HOST}/${VERSION}/users/revoke-token`; // used to log out user locally
export const CONFIRM_SIGNUP = `${HOST}/${VERSION}/users/confirm-signup`; // used to verify new user with the verification code
export const RESEND_CONFIRMATION_CODE = `${HOST}/${VERSION}/users/resend-confirmation-code`; // used to resend verification code to user
export const SEND_PASSWORD_VERIFICATION_CODE = `${HOST}/${VERSION}/users/send-password-verification-code`; // used to send forgot password code
export const CHECK_PASSWORD_VERIFICATION_CODE = `${HOST}/${VERSION}/users/check-password-verification-code`; // used to check forgot password code
export const CONFIRM_PASSWORD_VERIFICATION_CODE = `${HOST}/${VERSION}/users/confirm-password-verification-code`; // used to set new password
export const PASSWORD_OPTIONS = `${HOST}/${VERSION}/users/password-options`; // used to get code verification options for user
export const ME = `${HOST}/${VERSION}/users/me`;
export const USERS = `${HOST}/${VERSION}/users`; // used for update and getting data
export const VERIFY_USER = `${HOST}/${VERSION}/users/verify-user-attribute`;
export const VERIFY_EXISTING_USER = `${HOST}/${VERSION}/users/verify-existing-user-attribute`;
export const SEND_USER_VERIFY_CODE = `${HOST}/${VERSION}/users/send-user-attribute-verification-code`;
export const STORE_DATA = `${HOST}/${VERSION}/stores`;

export const STORE_DETAILS = `${HOST}/${VERSION}/stores/`;
export const STORE_SLUG = `${HOST}/${VERSION}/stores/bySlug`;
export const FILE_DOWNLOADS = `${HOST}/cms/menu`;
export const STORES = `${HOST}/${VERSION}/stores`;
export const MENU = `${HOST}/${VERSION}/menu`;
export const BASKET = `${HOST}/${VERSION}/baskets`;
export const NOTIFICATIONS = `${HOST}/${VERSION}/notifications`;
export const ORDER_DETAILS = `${HOST}/${VERSION}/orders/`;
export const CHECKIN_CURBSIDE_ORDER = `${HOST}/${VERSION}/orders/track/arrivalNotice`;
export const GET_VEHICLE_INFO = `${HOST}/${VERSION}/users/vehicleInfo`;
export const ADDVEHICLEINFO = `${HOST}/${VERSION}/users/vehicleInfo`;
export const CMS = `${HOST}/cms`;
export const CAREERS_STORE_LIST = `${HOST}/${VERSION}/stores/careers`;

export const CMS_CHECKOUT = `${CMS}/checkout`;
export const CMS_HOME = `${CMS}/web-home`;
export const CMS_FOOTER = `${CMS}/web-footer`;
export const CMS_MENUS = `${CMS}/web-menus`;
export const CMS_STORE_DETAILS = `${CMS}/web-store-details`;
export const CMS_VIOLATOR_BAR = `${CMS}/web-violator-bar`;
export const CMS_FAQ = `${CMS}/faq-pages`;
export const CMS_LEGAL = `${CMS}/legal-pages`;
export const CMS_FRANCHISE_SITEMAP_LINKS = `${CMS}/franchise-seo`;
export const CMS_SEO = `${CMS}/web-seo-overrides`;
export const CMS_RETIRED_MENU = `${CMS}/web-retired-menu`;
export const CMS_CAREERS = `${CMS}/web-careers-page`;
export const CMS_PROMOTIONAL = `${CMS}/web-promotional-pages`;
export const CMS_INTERIOR = `${CMS}/web-interior-pages`;
export const CMS_RESTAURANT_OPPORTUNITIES = `${CMS}/web-restaurant-opportunities-page`;
export const CMS_CORPORATE_OPPORTUNITIES = `${CMS}/web-corporate-opportunities-page`;
export const CMS_ABOUT_US = `${CMS}/web-about-page`;
export const CMS_LEADERSHIP = `${CMS}/web-leadership-page`;
export const CMS_GIFT_CARDS = `${CMS}/web-gift-cards-page`;
export const CMS_ARTICLES = `${CMS}/web-news-articles`;
export const CMS_NEWS_PAGE = `${CMS}/web-news-page`;
export const CMS_HISTORY = `${CMS}/web-history-page`;
export const CMS_RESPONSIBILITY = `${CMS}/web-responsibility-page`;
export const CMS_COMMUNITY = `${CMS}/web-community-page`;
export const CMS_CULTURE = `${CMS}/web-culture-page`;
export const CMS_CONTACT_US = `${CMS}/web-contact-page`;
export const CMS_DOWN = `${CMS}/web-down-page`;
export const CMS_404 = `${CMS}/web-404-page`;
export const CMS_REWARDS = `${CMS}/web-rewards-page`;
export const CMS_DELIVERY_PARTNERS = `${CMS}/web-delivery-page`;
export const CMS_GRAPHQL = `${CMS}/graphql`;
export const CMS_INVITE_FRIENDS = `${CMS}/web-account-invite-friends-page`;
export const CMS_ACTIVATE_OFFER_COUPON = `${CMS}/web-punchh-code-offer`;
export const CMS_PAYMENT_TEXT = `${CMS}/web-gift-card-optimization`;
export const CMS_SIGNIN_SIGNUP_MESSAGE = `${CMS}/signup-login-message`;
export const CMS_ACCOUNT_GIFTCARDS = `${CMS}/web-account-giftcard`;
export const CMS_USER_ACCOUNT_PROFILE = `${CMS}/user-account-profile`;
export const CMS_SELECT_STORE_MODAL = `${CMS}/select-store-modal`;
export const CMS_LOCATION_VERIFICATION_CHECKOUT = `${CMS}/location-verification-checkout`;
export const CMS_CHECKOUT_PAGE_DATA = `${CMS}/web-checkout`;
export const CMS_WEB_CART_TEXT = `${CMS}/web-cart`;
export const CMS_WEB_GAMIFICATION = `${CMS}/web-gamification`;

export const CONFIGURATION = `${HOST}/${VERSION}/configuration`;
export const PAYMENT = `${HOST}/${VERSION}/payments`;
export const PAYMENT_INIT = `${PAYMENT}/init`;

export const GIFTCARDS_BASE_URL = `${HOST}/${VERSION}/payments/giftcards`;
export const ADD_GIFTCARDS_WITHOUT_SAVE = `${HOST}/${VERSION}/payments/giftcards/balance`;

export const STOREORDERSTATUS = `${HOST}/${VERSION}/stores`;
export const ORDER_STATUS = `${HOST}/${VERSION}/orders`;
export const REWARDS_ESTIMATE_POINTS_EARNED = `${HOST}/${VERSION}/loyalty/rewards/estimate-points-earning`;

export const GIFTCARDS = {
  addExistingGiftCard: {
    apiEndPoint: GIFTCARDS_BASE_URL,
    requestType: "POST",
    isAuth: true,
  },
  addGiftCardWithoutSave: {
    apiEndPoint: ADD_GIFTCARDS_WITHOUT_SAVE,
    requestType: "POST",
    isAuth: false,
  },
  deleteGiftCard: {
    apiEndPoint: GIFTCARDS_BASE_URL,
    requestType: "DELETE",
    isAuth: true,
  },
  getGiftCardById: {
    apiEndPoint: GIFTCARDS_BASE_URL,
    requestType: "GET",
    isAuth: true,
  },
  getGiftCards: {
    apiEndPoint: GIFTCARDS_BASE_URL,
    requestType: "GET",
    isAuth: true,
  },
  updateGiftCard: {
    apiEndPoint: GIFTCARDS_BASE_URL,
    requestType: "PUT",
    isAuth: true,
  },
};

export const REWARDS_LOYALTY_BASE_URL = `${HOST}/${VERSION}/loyalty`;
export const REWARDS_BASE_URL = `${HOST}/${VERSION}/loyalty/rewards`;
export const REWARDS_GUEST_BASE_URL = `${HOST}/${VERSION}/loyalty/guest/rewards`;

export const REWARDS = {
  applyRewardToBasket: {
    apiEndPoint: REWARDS_BASE_URL,
    requestType: "PUT",
    isAuth: true,
  },
  getRewardsForBasket: {
    apiEndPoint: REWARDS_BASE_URL,
    requestType: "GET",
    isAuth: true,
  },
  getRewardsData: {
    apiEndPoint: REWARDS_BASE_URL,
    requestType: "GET",
    isAuth: true,
  },
  deleteRewardForBasket: {
    apiEndPoint: REWARDS_BASE_URL,
    requestType: "DELETE",
    isAuth: true,
  },
  getUserRewardHistory: {
    apiEndPoint: `${REWARDS_BASE_URL}/history`,
    requestType: "GET",
    isAuth: true,
  },
  getRewardUserCodeToScan: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/profile`,
    requestType: "GET",
    isAuth: true,
  },
  redeemInstoreReward: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/redemption`,
    requestType: "POST",
    isAuth: true,
  },
  deleteRedemptionTrackingCode: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/redemption`,
    requestType: "DELETE",
    isAuth: true,
  },
  applyPromoCodeToBasket: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/promotions`,
    requestType: "PUT",
    isAuth: false,
  },
  deletePromoCodeFromBasket: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/promotions`,
    requestType: "DELETE",
    isAuth: false,
  },
  getRewardsForGuest: {
    apiEndPoint: REWARDS_GUEST_BASE_URL,
    requestType: "GET",
    isAuth: false,
  },
  getPointsWithManualCheckins: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/checkins/manual`,
    requestType: "POST",
    isAuth: true,
  },
  getActivateYourOfferCoupons: {
    apiEndPoint: `${REWARDS_LOYALTY_BASE_URL}/coupons`,
    requestType: "POST",
    isAuth: true,
  },
};

// Franchise endpoints
export const CMS_FRANCHISE_FOOTER = `${CMS}/franchise-footer`;
export const CMS_FRANCHISE_HOME = `${CMS}/franchise-web-home`;
export const CMS_FRANCHISE_ABOUT_US = `${CMS}/franchise-web-about-us`;
export const CMS_FRANCHISE_GET_STARTED = `${CMS}/franchise-contact-us`;
export const CMS_FRANCHISE_FAQ = `${CMS}/franchise-faq-pages`;
export const CMS_FRANCHISE_LEGAL = `${CMS}/franchise-legal-pages`;
export const CMS_WHY_ZAXBYS = `${CMS}/franchise-why-zaxby`;
export const CMS_FRANCHISE_INVESTMENT = `${CMS}/franchise-investment`;
export const CMS_FRANCHISE_PROCESS = `${CMS}/franchise-process`;
export const CMS_FRANCHISE_LOCATION = `${CMS}/franchise-available-market`;
export const CMS_FRANCHISE_AWARD = `${CMS}/franchise-award`;
export const CMS_FRANCHISE_LEADERSHIP = `${CMS}/franchise-leadership`;
export const CMS_FRANCHISE_TESTIMONIAL = `${CMS}/franchise-testimonial`;
export const CMS_FRANCHISE_PROFILE = `${CMS}/franchise-profile`;
export const CMS_FRANCHISE_TRAINING = `${CMS}/franchise-training-support`;
export const CMS_FRANCHISE_BLOGS = `${CMS}/franchise-news-blog`;
export const CMS_FRANCHISE_BLOG_ITEMS = `${CMS}/franchise-news-blog-items?populate=*&sort[0]=createdAt:desc`;
export const CMS_FRANCHISE_BLOG_SINGLE_ITEM = `${CMS}/franchise-news-blog-items`;
export const FRANCHISE_GET_STARTED_SUBMIT = `${HOST}/${VERSION}/franchising`;

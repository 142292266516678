import { useRouter } from "next/router";
import { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import validator from "validator";
import { useSignup } from "hooks/auth/signup/useSignup";
import { ANALYTICS_CONST } from "constants/analytics";
import { PASSWORD_REGEX, PHONE_FORMAT_REGEX } from "constants/regexPatterns";
import Analytics from "analytics/Analytics";
import Button from "components/atomic-components/atoms/button/Button";
import Checkbox from "components/atomic-components/atoms/form-elements/Checkbox/Checkbox";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Link from "components/atomic-components/atoms/typography/Links/Links";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import CurveModal from "components/atomic-components/molecules/Modals/CurveModal/CurveModal";
import StackedButtonsContainer from "components/global/StackedButtonsContainer/StackedButtonsContainer";
import useModal from "hooks/useModal";
import { useCheckIsPageIdleForThirtyMins } from "hooks/useCheckIsIdleForThirtyMins";
import { ApiErrorResponse, CommonLayerResponseData } from "src/types/types";
import { cleanEmail, cleanName, transformDashPhone } from "utils/cleaning";
import { deleteUser } from "api/users/delete-user";
import { resetSignupData } from "redux/reducer/auth/signup/actions";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import {
  Body,
  Checkboxes,
  Footer,
  Header,
  Inputs,
  SignInCheckboxes,
  SignUpErrorComponent,
  SignUpModalStyled,
  SignupSubText,
  VerficationText,
  VerficationTextGroup,
  Wrapper,
} from "./SignupStyled";

const Signup = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [serverError, setServerError] = useState<string | null>(null);
  const [password, setPassword] = useState("");
  const [hasCode, setHasCode] = useState(false);
  const [isPasswordStrengthIndicatorVisible, setIsPasswordStrengthIndicatorVisible] = useState(false);
  const [isPasswordFieldoutOfFocus, setIsPasswordFieldoutOfFocus] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(true);

  const { modal, setModal } = useModal();
  const { getInputProps, handleSubmit, handleChange, handleBlur, isLoading, errorExists, values } = useSignup();
  const { isPageIdleForThirtyMins, setIsPageIdleForThirtyMins, setLastActionTime } = useCheckIsPageIdleForThirtyMins();

  const referralCode = sessionStorage.getItem("referralCode");

  const handleGoToLogin = () => {
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SIGN_IN_STARTED, { placement: "sign up screen" }, true, false);
    setModal("LOGIN");
  };

  const handleGoToVerify = () => setModal("VERIFY");
  const handleCloseModal = () => {
    setModal("");
    dispatch(resetSignupData());
    if (referralCode) {
      sessionStorage.removeItem("referralCode");
    }
    if ((router?.pathname === "/" || router?.pathname === "/home") && "page" in router?.query) {
      router.replace("/");
    }
  };

  const handleError = (data: ApiErrorResponse | undefined) => {
    if (data?.errorCode === 400) {
      setServerError(data?.message ?? null);
    }
  };

  /**
   * This function will be called while selecting email and text option to receive communications
   * @param name field name
   * @param value filed value
   */
  const onChange = (name: string, value: boolean) => {
    const event = {
      emailCommunication: value ? ANALYTICS_CONST.AUTH.EMAIL_OPTIN_SELECTED : ANALYTICS_CONST.AUTH.EMAIL_OPTIN_DESELECTED,
      textCommunication: value ? ANALYTICS_CONST.AUTH.TEXT_OPTIN_SELECTED : ANALYTICS_CONST.AUTH.TEXT_OPTIN_DESELECTED,
    }[name];

    setLastActionTime(new Date().getTime());
    if (event) Analytics.getInstance().logEvent(event);
    if (name === "emailCommunication") {
      setIsEmailChecked(!isEmailChecked);
      handleChange("emailCommunication", value);
    } else if (name === "hasCode") {
      handleChange("hasCode", value);
      handleChange("referralCode", "");
      setHasCode(value);

      if (value) {
        // Added click_share_referral_code in amplitude
        Analytics.getInstance().logEvent(ANALYTICS_CONST.REWARD.SELECT_REFERRAL_BY_FRIEND);
      }
    } else {
      handleChange("textCommunication", value);
    }
  };

  const handleSignup = (e: FormEvent) => {
    e.preventDefault();
    // Added amplitude sign_up_started event
    Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SIGN_UP_STARTED, {
      placement: "sign up screen",
    });

    handleSubmit()
      .then((res: CommonLayerResponseData) => {
        if (res.success) {
          // Added amplitude user id
          if (res.response.cdpId) {
            Analytics.getInstance().setUserId(res.response.cdpId.toUpperCase());
          }
          // Added amplitude sign_up_success event
          Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SIGN_UP_SUCCESS, {
            referral: res.response.referralCodeValid ? "yes" : "no",
          });

          handleGoToVerify();
        } else {
          // Added amplitude signin_failure event
          Analytics.getInstance().logEvent(ANALYTICS_CONST.AUTH.SIGN_UP_FAILURE);

          handleError(res.error);
        }
      })
      .catch((err: any) => {
        handleError(err);
      });
  };

  useEffect(() => {
    if (referralCode || values?.hasCode) {
      handleChange("hasCode", referralCode ?? values?.hasCode);
      handleChange("referralCode", referralCode ?? values?.referralCode);
      setHasCode(true);

      if (hasCode) {
        // Added click_share_referral_code in amplitude
        Analytics.getInstance().logEvent(ANALYTICS_CONST.REWARD.SELECT_REFERRAL_BY_FRIEND);
      }
    }
  }, [referralCode, values?.hasCode]);

  // If the page is Idle for 30 mins then clear the entered PHONE NUMBER and PASSWORD fields
  useEffect(() => {
    if (isPageIdleForThirtyMins) {
      deleteUser({ email: values?.email ?? "", phone: values?.phone ?? "", shouldFinish: false });
      setIsPageIdleForThirtyMins(false);
      handleChange("phone", "");
      handleChange("password", "");
      dispatch(resetSignupData());
    }
  }, [isPageIdleForThirtyMins]);

  const isShowSignUpButton =
    values?.password.match(PASSWORD_REGEX) && validator.isEmail(values?.email) && values?.phone.match(PHONE_FORMAT_REGEX);
  const isserverErrorExist = serverError !== null;
  const clearErrorMessageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target.name) {
      ["phone", "email"].includes(event.target.name) && setServerError(null);
    }
  };

  const handleUpdateActionTime = () => {
    setLastActionTime(new Date().getTime());
  };

  const BODY = (
    <Body>
      <form
        onSubmit={handleSignup}
        onChange={(e: any) => {
          clearErrorMessageHandler(e);
        }}
      >
        <Header>
          <Headings variant="primaryHeading1" fontSize={34} fontWeight={700} className="welcomeBackTitle">
            SIGN UP
          </Headings>
          <SubHeadings variant="primarySubHeading2" fontSize={24} fontWeight={800} className="welcomeAuthSubTitle">
            TO UNLOCK FREE FOOD
          </SubHeadings>
        </Header>
        <Wrapper>
          <Checkboxes>
            <Checkbox variant="primaryBlueFilled" {...getInputProps<boolean>("hasCode")} className="signupCheckbox" onChange={onChange}>
              HAVE A CODE FROM A FRIEND?
            </Checkbox>
            <div />
          </Checkboxes>
          <Inputs>
            {hasCode && (
              <TextField
                className="enterCode"
                label="Enter Code"
                {...getInputProps("referralCode")}
                onChange={(name: string, value: string) => {
                  handleChange("referralCode", value);
                  handleUpdateActionTime();
                }}
                maxLength={20}
                cleanFunction={cleanName}
                autoFocus
              />
            )}
            <TextField
              label="First Name"
              {...getInputProps("firstName")}
              onChange={(name: string, value: string) => {
                handleChange("firstName", value);
                handleUpdateActionTime();
              }}
              maxLength={25}
              cleanFunction={cleanName}
              autoFocus
            />
            <TextField
              label="Last Name"
              {...getInputProps("lastName")}
              onChange={(name: string, value: string) => {
                handleChange("lastName", value);
                handleUpdateActionTime();
              }}
              maxLength={25}
              cleanFunction={cleanName}
            />
            <TextField
              type="tel"
              label="Mobile Phone"
              {...getInputProps("phone")}
              onChange={(name: string, value: string) => {
                handleChange("phone", value);
                handleUpdateActionTime();
              }}
              maxLength={12}
              cleanFunction={transformDashPhone}
            />
            {values?.phone && !getInputProps("phone").error && (
              <VerficationTextGroup>
                <div></div>
                <VerficationText className="verificationText">We’ll text a verification code to this number</VerficationText>
              </VerficationTextGroup>
            )}
            <TextField cleanFunction={cleanEmail} label="Email" maxLength={250} {...getInputProps("email")} />
            <TextField
              type="password"
              label="Password"
              {...getInputProps("password")}
              onChange={(name: string, value: string) => {
                handleChange("password", value);
                setPassword(value);
                handleUpdateActionTime();
              }}
              onBlur={(name: string, value: string) => {
                handleBlur("password", value);
                setIsPasswordFieldoutOfFocus(true);
                if (password.length === 0) {
                  setIsPasswordStrengthIndicatorVisible(false);
                }
              }}
              onFocus={() => setIsPasswordStrengthIndicatorVisible(true)}
              maxLength={50}
            />
          </Inputs>
          {isPasswordStrengthIndicatorVisible && (
            <PasswordStrengthIndicator isPasswordFieldoutOfFocus={isPasswordFieldoutOfFocus} password={password} />
          )}
          <SignInCheckboxes>
            <Checkbox
              variant="primaryBlueFilled"
              {...getInputProps<boolean>("emailCommunication")}
              className="sendMeCheckbox"
              onChange={onChange}
              value={isEmailChecked}
            >
              Send me emails about Zaxbys offers & more
            </Checkbox>
            <div />
          </SignInCheckboxes>
        </Wrapper>

        <SignupSubText className="passwordRequirement">
          <div className="text">
            By clicking “Sign Up” below, I agree to Zaxbys{" "}
            <Link
              to="/legal/rewards-policy"
              target="_blank"
              className="TermLinkAction"
              rel="noopener noreferrer"
              onClick={handleUpdateActionTime}
            >
              REWARDS POLICY,
            </Link>{" "}
            <Link
              to="/legal/terms-of-use"
              target="_blank"
              className="TermLinkAction"
              rel="noopener noreferrer"
              onClick={handleUpdateActionTime}
            >
              TERMS OF USE,
            </Link>{" "}
            &{" "}
            <Link
              to="/legal/privacy-policy"
              target="_blank"
              className="TermLinkAction"
              rel="noopener noreferrer"
              onClick={handleUpdateActionTime}
            >
              PRIVACY POLICY
            </Link>
            .
          </div>
        </SignupSubText>

        <StackedButtonsContainer direction="row" className="signupActions">
          <Button
            type="submit"
            variant="primaryBlue"
            disabled={
              isserverErrorExist || isLoading || errorExists || !isShowSignUpButton || (values && values?.hasCode && !values?.referralCode)
            }
            className="pButton"
          >
            Sign Up
          </Button>
        </StackedButtonsContainer>
        {serverError && (
          <SignUpErrorComponent>
            <Paragraph className="errorMessage" variant={"secondaryParagraph1"}>
              THERE WAS A PROBLEM WITH THE INFO YOU ENTERED
            </Paragraph>
            <Paragraph className="orMessage" variant={"secondaryParagraph1"}>
              Check your info above and try again OR Login below
            </Paragraph>
          </SignUpErrorComponent>
        )}
        <div></div>
      </form>
    </Body>
  );

  const FOOTER = (
    <Footer>
      <div className="heading">ALREADY HAVE AN ACCOUNT?</div>
      <StackedButtonsContainer direction="row" className="signupActions">
        <Button className="pButton" type="button" variant="primaryPureWhite" onClick={handleGoToLogin}>
          Log in
        </Button>
      </StackedButtonsContainer>
    </Footer>
  );

  return (
    <SignUpModalStyled>
      <CurveModal
        isOpen={modal === "SIGNUP"}
        body={BODY}
        footer={FOOTER}
        onClose={handleCloseModal}
        showCloseButton
        mobileVariant="fullScreen"
      />
    </SignUpModalStyled>
  );
};

export default Signup;
